#userAccount {
    .mat-cell{
        max-width: 250px !important;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.mat-paginator {
    display: block;
    background-color: transparent !important;
}

.mat-paginator-outer-container {
    display: flex;
}

.mat-paginator-container {
    display: flex;
    align-items: center;
    justify-content: center !important;
    padding: 0 8px;
    flex-wrap: wrap-reverse;
    width: 100%;
    // opacity: 0 !important;
}

.mat-paginator-page-size {
    width: 150px;
    display: flex;
    align-items: baseline;
    margin-right: 8px;
    flex-direction: row !important;
}

.mat-paginator-icon {
    width: 1.5rem !important;
    height: 1.5rem;
    background: #0740B9 0% 0% no-repeat padding-box;
    border: none !important;
    color: white;
}

.mat-dialog-content {
    display: block;
    margin: 0 -24px;
    padding: 0 24px;
    max-height: 70vh !important;
    overflow: auto;
}

.mat-select-arrow-wrapper {
    height: 0px !important;
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
}

.mat-dialog-container {
    border-radius: 0.75rem !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-accent .mat-pseudo-checkbox-checked, .mat-accent .mat-pseudo-checkbox-indeterminate, .mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-indeterminate {
    background-color: #0640B9 !important;
}

.mat-form-field-appearance-fill .mat-form-field-subscript-wrapper {
     padding: 0px !important;
}

.mat-error {
    margin-top: 1.25rem!important;
}

.errorMsg, .mat-error {
    margin-top: 0 !important;
    padding: 0 !important;
}

.mat-form-field-appearance-fill .mat-form-field-subscript-wrapper {
    padding: 0px !important;
    transform: translateY(15px);
}

.mat-menu-panel-transparent {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

span.mat-select-placeholder {
    background-color: white;
    color: black
}

div.mat-paginator-page-size-label, div.mat-paginator-range-label {
    background-color: transparent;
    color: black;
  }

.custom-tooltip .mat-tooltip {
max-width: 800px;
}

