.mat-dialog-container {
    box-shadow: inset 0px 3px 15px #E7F0F6, 0px 0px 15px #4973D526;
    border-radius: 40px;
    opacity: 1;
    width: max-content !important;
}

.mat-dialog-container #classroomAssignment {
    min-width: 750px !important;
}

.mat-dialog-container #classroom {
    width: 700px !important;
}

.mat-dialog-container #role {
    width: 600px !important;
}

.mat-dialog-container  #addFeatureDialog {
    width: 600px !important;
}

.mat-dialog-container  #superFeatureDialog {
    width: 600px !important;
}

.mat-dialog-container  #completeEnrollmentDialog {
    width: 600px !important;
}

.mat-dialog-container  #selectRating {
    width: 1000px !important;
    height: 550px !important;
}

.mat-dialog-container  #addProviderDialog {
    width: 600px !important;
}